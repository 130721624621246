// import utlagiVideo from '../media/utlagi.mp4';
import utlagiCover from '../img/utlagi-cover.webp';
import assoCover from '../img/asso.webp';
import retroGameCover from '../img/retro-gaming.webp';
import myTripInUkraine from "../img/my-trip-in-ukraine.webp";
import reactNativeApp from "../img/react-native-app.webp";

const Projects = () => {
    

    return (
      <main className="flex-column space-between projects-section">
        <h2>Projects</h2>
          <div className="projects-wrapper flex-column space-between">

            <div className="project flex-column">
              <div className='top-bar'>
                <div className='circle circle-red'></div>
                <div className='circle circle-yellow'></div>
                <div className='circle circle-green'></div>
              </div>
              <div className="project-content">
                <div className="media flex-row center">
                  <img
                    loading="lazy"
                    alt="Utlagi - Maison d'édition normande et nordique"
                    src={utlagiCover}
                    width="300px"
                    height="100px"
                  />
                </div>
                <div className="description">
                  <p>This is my first website and final project during my Web development training course at the 3W Academy. I chose to create an e-commerce website for a (for now) fictive publishing house.</p>

                  <p>Technologies used in this project :</p>
                  <div className="tech_btn_wrapper">
                    <span className="tech_btn">Figma</span>
                    <span className="tech_btn">HTML/CSS</span>
                    <span className="tech_btn">Express.js</span>
                    <span className="tech_btn">EJS templates</span>
                    <span className="tech_btn">React.js</span>
                    <span className="tech_btn">Webpack</span>
                    <span className="tech_btn">MongoDB</span>
                  </div>

                  <div className="redirect-btn-wrapper">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="demo_link"
                        href="https://utlagi.marie-occelli.fr"
                      >
                        Access Demo
                      </a>
                  </div>
                </div>
              </div>
            </div>


            <div className="project flex-column">
              <div className='top-bar'>
                <div className='circle circle-red'></div>
                <div className='circle circle-yellow'></div>
                <div className='circle circle-green'></div>
              </div>
              <div className="project-content">
                <div className="media flex-row center">
                  <img
                    loading="lazy"
                    alt="projet asso"
                    src={assoCover}
                    width="300px"
                    height="100px"
                  />
                </div>
                <div className="description">
                  <p>This is a website created for the association “Le Musée du Lycée Marie Curie de Sceaux“ based in Sceaux, France. The website is aimed to present the history of the college Marie-Curie and its latest news.</p>
                  <p>Work still in progress ! All the pages may not be available.</p>

                  <p>Technologies used in this project :</p>
                  <div className="tech_btn_wrapper">
                    <span className="tech_btn">Figma</span>
                    <span className="tech_btn">HTML/CSS</span>
                    <span className="tech_btn">SASS</span>
                    <span className="tech_btn">ES6</span>
                    <span className="tech_btn">Express.js</span>
                    <span className="tech_btn">EJS templates</span>
                    <span className="tech_btn">React.js</span>
                    <span className="tech_btn">Webpack</span>
                    <span className="tech_btn">MongoDB</span>
                  </div>

                  <div className="redirect-btn-wrapper">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="demo_link"
                        href="http://lycee-marie-curie.marie-occelli.fr"
                        >
                          Access demo
                        </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="project flex-column">
              <div className='top-bar'>
                <div className='circle circle-red'></div>
                <div className='circle circle-yellow'></div>
                <div className='circle circle-green'></div>
              </div>
              <div className="project-content">
                <div className="media flex-row center">
                  <img
                    loading="lazy"
                    alt="Retro gaming arkanoid"
                    src={retroGameCover}
                    width="300px"
                    height="100px"
                  />
                </div>
                <div className="description">
                  <p>Retro gaming Arkanoid created with plain Vanilla JS, using Canvas 2D API and JS classes.</p>
                  <p>This game works only on desktop viewport.</p>

                  <p>Technologies used in this project :</p>
                  <div className="tech_btn_wrapper">
                    <span className="tech_btn">HTML/CSS</span>
                    <span className="tech_btn">Express.js</span>
                    <span className="tech_btn">Vanilla JS</span>
                  </div>

                  <div className="redirect-btn-wrapper">
                    <a href="https://github.com/marieoc/brick-breaker-game-js">
                      <i className="fa-brands fa-github neon-blue"></i>
                    </a>
                    
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="demo_link"
                      href="https://brick-breaker.marie-occelli.fr"
                    >
                      Access demo
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="project flex-column">
              <div className='top-bar'>
                <div className='circle circle-red'></div>
                <div className='circle circle-yellow'></div>
                <div className='circle circle-green'></div>
              </div>
              <div className="project-content">
                <div className="media flex-row center">
                  <img
                    loading="lazy"
                    alt="My Trip In Ukraine"
                    src={myTripInUkraine}
                    width="300px"
                    height="100px"
                  />
                </div>
                <div className="description">
                  <p>Multilingual website for a fictitious agency offering guided tours in Ukraine. (Work in progress)</p>

                  <p>Technologies used in this project :</p>
                  <div className="tech_btn_wrapper">
                    <span className="tech_btn">Figma</span>
                    <span className="tech_btn">Laravel</span>
                    <span className="tech_btn">MySQL</span>
                    <span className="tech_btn">Livewire</span>
                    <span className="tech_btn">Alpine.JS</span>
                    <span className="tech_btn">SASS</span>
                    <span className="tech_btn">Webpack</span>
                  </div>

                  <div className="redirect-btn-wrapper">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="demo_link"
                      href="https://my-trip-in-ukraine.com/en"
                    >
                      Access demo
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <div className="project flex-column">
              <div className='top-bar'>
                <div className='circle circle-red'></div>
                <div className='circle circle-yellow'></div>
                <div className='circle circle-green'></div>
              </div>
              <div className="project-content">
                <div className="media flex-row center">
                  <img
                    loading="lazy"
                    alt="React Native App"
                    src={reactNativeApp}
                    style={{ objectFit: 'contain', maxHeight: "300px" }}
                  />
                </div>
                <div className="description">
                  <p>Mobile application for managing training sessions and matches of a Gaelic football club. App built with React Native and a RESTful API with Laravel for the backend. (Work in progress)</p>

                  <p>Technologies used in this project :</p>
                  <div className="tech_btn_wrapper">
                    <span className="tech_btn">Figma</span>
                    <span className="tech_btn">React</span>
                    <span className="tech_btn">React Native</span>
                    <span className="tech_btn">Expo Go</span>
                    <span className="tech_btn">Tailwind CSS</span>
                    <span className="tech_btn">Laravel</span>
                    <span className="tech_btn">MySQL</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
      </main>
    );
  }
  
  export default Projects;

  
        
  